<template>
    <div>
        <div class="col-md-12">
            <div class="row" style="margin-top:20px">
                <div class="col-md-6">
                    <h3 class="page-header">
                    <i class="bx bx-send animated bounceInDown show-info"></i>
                    Jobs
                    </h3>
                </div>
                <div class="col-md-6" style="text-align: end;" v-if="viewno==2">
                    <button @click="back()" class="btn btn-primary" style="padding-top: 18px; justify-content: flex-end;">Back</button>
                </div>
            </div>
        </div>
        <div class="col-md-12">
            <div class="info-box bg-primary text-white">
                <div class="info-icon bg-warning-dark">
                    <i class="fa fa fa-user fa-2x"></i>
                </div>
                <div class="info-details">
                    <h4>Job Form</h4>
                </div>
            </div>
            <div class="flex-row">
                <div class="col-md-6">
                    <div class="panel panel-cascade">
                        
                        <div class="panel-body" style="height:500px">
                            <div class="form-horizontal cascde-forms">
                            
                                <div class="form-group flex-row">
                                    <label class="col-lg-4 col-md-3 control-label">Name<span style="color:red">*</span> </label>
                                    <div class="col-lg-8 col-md-9">
                                        <input class="form-control form-cascade-control input-small" v-model="name" type="text" value="" />
                                    </div>
                                </div>
                                <div class="form-group flex-row" :class="{ 'form-group--error': $v.mobileno.$error }">
                                    <label class="col-lg-4 col-md-3 control-label">Mobile Number <span style="color:rgba(255, 0, 0, 0.67)">*</span></label>
                                    <div class="col-lg-8 col-md-9">
                                        <input class="form-control form-cascade-control input-small" v-model="mobileno" max="10" type="number" @change="mobileduplicate()"/>
                                        <span class="error" v-if="!$v.mobileno.required">Field is required</span>
                                        <span class="error" v-if="!$v.mobileno.maxLength">Mobile No. should not exceed {{$v.mobileno.$params.maxLength.max}} letters.</span>
                                        <span class="error" v-if="!$v.mobileno.minLength">Mobile No. should of minimum 10 digit</span>
                                        <span class="error" v-if="checkduplicate">Mobile No. Already Exists</span>
                                    </div>
                                </div>
                                <div class="form-group flex-row">
                                    <label class="col-lg-4 col-md-3 control-label">Alt. Mobile No.</label>
                                    <div class="col-lg-8 col-md-9">
                                        <input class="form-control form-cascade-control input-small" v-model="altmobileno" max="10" type="number" @change="mobileduplicate()"/>
                                        <span class="error" v-if="checkduplicate">Mobile No. Already Exists</span>
                                    </div>
                                </div>
                                <div class="form-group flex-row">
                                    <label class="col-lg-4 col-md-3 control-label">Address <span style="color:rgba(255, 0, 0, 0.67)">*</span></label>
                                    <div class="col-lg-8 col-md-9">
                                        <textarea class="form-control form-cascade-control input-small" cols="20" v-model="address" rows="2">
                                        </textarea>
                                    </div>
                                </div>
                                <div class="form-group row">
                                <label class="col-lg-4 col-md-3 control-label">Gender <span style="color:rgba(255, 0, 0, 0.67)">*</span></label>
                                    <div class="col-lg-8 col-md-9">
                                        <select v-model="gender" class="form-control delradius form-cascade-control">
                                            <option v-for="option in getmastertype(MASTER_GENDER)"
                                                v-bind:key="option.labelid" v-bind:value="option.labelid"
                                                v-text="option.label" />
                                        </select>
                                    </div>
                                    
                                </div>
                                <div class="form-group row">
                                    <label class="col-lg-4 col-md-3 control-label">Date Of Birth</label>
                                    <div class="col-lg-8 col-md-9">
                                        <input class="form-control form-cascade-control input-small" v-model="dob"  type="date"/>
                                    </div>
                                </div>
                                <div class="form-group row">
                                    <label class="col-lg-4 col-md-3 control-label">City</label>
                                    <div class="col-lg-8 col-md-9">
                                        <select class="form-control form-cascade-control input-small .dropdown-menu" style="background-color: rgb(255, 255, 255);" v-model="city" required>
                                            <option v-for="(item) in getmastertype(MASTER_CITIES)" v-bind:key="item.labelid" :value="item.labelid">{{ item.label }}</option>
                                        </select>
                                        <span class="error" v-if="!$v.city.required">Field is required</span>
                                    </div>
                                </div>
                                <div class="form-group flex-row">
                                    <label class="col-lg-4 col-md-3 control-label">Pincode</label>
                                    <div class="col-lg-8 col-md-9">
                                        <input class="form-control form-cascade-control input-small" v-model="pincode" max="6" type="number" />
                                       
                                    </div>
                                </div>
                               
                                <div class="form-group flex-row">
                                    <div class="col-lg-12 col-md-9">
                                        
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="col-md-6">
                    <div class="panel panel-cascade">
                        <div class="panel-body" style="height:450px">
                            <div class="form-horizontal cascde-forms">
                                <div class="form-group flex-row">
                                    <label class="col-lg-4 col-md-3 control-label">Experience in Years</label>
                                    <div class="col-lg-8 col-md-9">
                                        <input class="form-control form-cascade-control input-small" v-model="experience" max="10" placeholder="Experience in Years" type="number"/>
                                    </div>
                                </div>
                                <div class="form-group flex-row">
                                    <label class="col-lg-4 col-md-3 control-label">Apply For<span style="color:rgba(255, 0, 0, 0.67)">*</span></label>
                                    <div class="col-lg-8 col-md-9">
                                        <select class="form-control form-cascade-control input-small" v-model="position"><option value="0">SELECT</option>
                                            <option v-for="option in getmastertype(APPLY_FOR_POSITION)"
                                            v-bind:key="option.labelid" v-bind:value="option.labelid"
                                            v-text="option.label" />
                                        </select>
                                    </div>
                                </div>
                                <div class="form-group flex-row">
                                    <label class="col-lg-4 col-md-3 control-label">Date of Application<span style="color:rgba(255, 0, 0, 0.67)">*</span></label>
                                    <div class="col-lg-8 col-md-9">
                                        <input class="form-control form-cascade-control input-small" v-model="date" max="10" type="date"/>
                                    </div>
                                </div>
                                <div class="form-group flex-row">
                                    <label class="col-lg-4 col-md-3 control-label">Qualification</label>
                                    <div class="col-lg-8 col-md-9">
                                        <select class="form-control form-cascade-control input-small" v-model="qualification"><option value="0">SELECT</option>
                                            <option v-for="option in getmastertype(QUALIFICATION_TYPE)"
                                            v-bind:key="option.labelid" v-bind:value="option.labelid"
                                            v-text="option.label" />
                                        </select>
                                    </div>
                                </div>
                                <!-- <div class="form-group flex-row">
                                    <label class="col-lg-4 col-md-3 control-label">Status<span style="color:rgba(255, 0, 0, 0.67)">*</span></label>
                                    <div class="col-lg-8 col-md-9">
                                        <select class="form-control form-cascade-control input-small" v-model="status" name="status">
                                            <option value="0">SELECT</option>
                                            <option value="1">SHORT LISTED</option>
                                            <option value="2">APPOINTED</option>
                                            <option value="3">REJECTED</option>
                                            <option value="4">TERMINATED</option>
                                        </select>
                                    </div>
                                </div> -->
                                <div class="form-group flex-row">
                                    <label class="col-lg-4 col-md-3 control-label">Refrence By</label>
                                    <div class="col-lg-8 col-md-9">
                                        <input type="text" class="form-control form-cascade-control input-small" v-model="referby" >
                                    </div>
                                </div>
                                <div class="form-group flex-row">
                                    <label class="col-lg-4 col-md-3 control-label">Description</label>
                                    <div class="col-lg-8 col-md-9">
                                        <textarea class="form-control form-cascade-control input-small" v-model="description" placeholder="Description " type="text"></textarea>
                                    </div>
                                </div>
                                <div class="form-group flex-row">
                                    <label class="col-lg-4 col-md-3 control-label">Work Time<span style="color:rgba(255, 0, 0, 0.67)">*</span></label>
                                    <div class="col-lg-8 col-md-9">
                                        <select class="form-control form-cascade-control input-small" v-model="worktime"><option value="0">SELECT</option>
                                            <option v-for="option in getmastertype(WORK_TIME)"
                                            v-bind:key="option.labelid" v-bind:value="option.labelid"
                                            v-text="option.label" />
                                        </select>
                                    </div>
                                </div>
                                <!-- <div class="form-group flex-row">
                                    <label class="col-lg-4 col-md-3 control-label">Remark<span style="color:rgba(255, 0, 0, 0.67)">*</span></label>
                                    <div class="col-lg-8 col-md-9">
                                        <select class="form-control form-cascade-control input-small" v-model="remark"><option value="0">SELECT</option>
                                            <option value="1">Excellent</option>
                                            <option value="2">Average</option>
                                            <option value="3">Poor</option>
                                        </select>
                                    </div>
                                </div> -->
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class=" col-md-12">
                <input v-if="!clickadvice" type="submit" value="Submit" class="btn bg-primary text-white btn-lg" @click="submit()">
                <span v-if="clickadvice" class="spinner"></span>
            </div>
        </div>
    </div>
</template>
<script>
import { mapGetters } from 'vuex'
import { required, maxLength,minLength } from 'vuelidate/lib/validators'
import Constants from '../../components/utilities/Constants.vue'
export default{
    mixins:[Constants],
    // components:{ReferralSelector},
    data(){
        return{
            name:'',
            mobileno:'',
            altmobileno:'',
            address:'',
            gender:1,
            experience:0,
            position:0,
            date:'',
            status:0,
            qualification:0,
            remark:0,
            id:0,
            city:'',
            pincode:0,
            checkduplicate:false,
            modelConfig: {
                type: 'string',
                mask: 'YYYY-MM-DD', // Uses 'iso' if missing
            },
            description:'',
            referby:'',
            worktime:2,
            dob:'',
            clickadvice:false
        }
    },
    validations:{
        mobileno:{
            required,
            minLength:minLength(10),
            maxLength:maxLength(10)
        },
        city:{
            required
        }
    },
    computed:{
        ...mapGetters(['employees','referraluser','loggedinuser','joblist','viewno'])
    },
    mounted(){
        if (this.$router.currentRoute.path == '/jobsform') {
            this.$store.commit("assignjoblist", null)
            
        }
            
        this.initializedata();
        this.refresh();
    },
    methods:{
        mobileduplicate(){
            if(this.joblist!=null){
                console.log("null job list")
            }else{

        
                let param={mobileno:this.mobileno}
                this.$http.post('api/recruit/check/mobile',param)
                .then((response) => this.processCheckResponse(response.data))
                .catch((err) => {
                    console.log('', err)
                });
            }
        },
        processCheckResponse(data){
            if(data==true){
                this.checkduplicate=true
            }else{
                this.checkduplicate=false
            }
        },
        refresh(){
            this.$store.dispatch('fetchemployees')                                                                  
        },
        initializedata(){
            if(this.joblist!=null){
                this.name=this.joblist.name
                this.mobileno=this.joblist.mobileno
                this.altmobileno=this.joblist.altmobileno
                this.address=this.joblist.address
                this.gender=this.joblist.gender
                this.experience=this.joblist.experience
                this.position=this.joblist.position
                this.date=this.joblist.date
                this.status=this.joblist.status
                this.qualification=this.joblist.qualification
                this.remark=this.joblist.remark
                this.id=this.joblist.id
                this.description=this.joblist.description
                this.referby=this.joblist.referby
                this.worktime=this.joblist.worktime
                this.pincode=this.joblist.pincode
                this.city=this.joblist.city
                this.dob=this.joblist.dob
            }else{
                this.name=''
                this.mobileno=''
                this.altmobileno=''
                this.address=''
                this.gender=1
                this.experience=0
                this.position=0
                this.date=''
                this.status=0
                this.qualification=0
                this.remark=0
                this.id=0
                this.checkduplicate=false
                this.description=''
                this.referby=''
                this.worktime=2
                this.city=1
                this.pincode=0
                this.dob=''
                this.clickadvice=false
            }
        },
        submit(){
            this.clickadvice=true
            this.$notify({text:'Submitted',type:'warn'})
            if(this.checkduplicate==false && !this.$v.$invalid && this.date!=''){
                let param = {
                    id:this.id,
                    name:this.name,
                    mobileno:this.mobileno,
                    altmobileno:this.altmobileno,
                    address:this.address,
                    gender:this.gender,
                    experience:this.experience,
                    position:this.position,
                    date:this.date,
                    status:this.status,
                    qualification:this.qualification,
                    remark:this.remark,
                    description:this.description,
                    referby:this.referby,
                    worktime:this.worktime,
                    dob:this.dob
                }
                this.$store.commit('assignloadingstatus',1)
                this.$http.post('api/recruit/createupdate',param)
                .then((response) => this.processLeadResponse(response.data))
                .catch((err) => {
                    console.log('', err)
                });
            }else{
                this.$notify({text:'Please Avoid the Error Mention in the Form',type:'error'})
            }
        },
        processLeadResponse(){
            this.$store.commit("assignjoblist", null)
            this.initializedata();
            this.$notify({text:'Submitted Successfully',type:'success'})
            this.$store.commit('assignloadingstatus',0)
            this.$store.commit('assignviewno',1)
            this.clickadvice=false
        },
        back(){
            this.$store.commit('assignviewno',1)
        }
    }
}
</script>
<style>
.flex-row{
    display: flex;
    justify-content: space-between;
    /* align-items: center; */
    flex-wrap: wrap;
}
</style>